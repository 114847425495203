import { CategoryKey, Variant } from "@warranty-keeper/common";
import { translate } from "../../../locale/Locale";
import Popup from "../../basic/Popup";
import Illustration from "../../basic/Illustration/Illustration";
import DeleteCategoryLight from "../../../assets/illustrations/delete-category-light.svg";
import DeleteCategoryDark from "../../../assets/illustrations/delete-category-dark.svg";

interface DeleteCategoryPopupProps {
  popupVisible: boolean;
  setPopupVisible: (visible: boolean) => void;
  categoryKey: CategoryKey;
  categoryLabel: string;
  onDeleteCategory: (categoryKey: CategoryKey) => void;
}

export default function DeleteCategoryPopup(props: DeleteCategoryPopupProps) {
  return (
    <Popup
      open={props.popupVisible}
      title={translate("common.deletingSomething", {
        something: props.categoryLabel,
      })}
      content={translate("common.areYouSure")}
      onClose={() => props.setPopupVisible(false)}
      illustration={
        <Illustration
          lightPath={DeleteCategoryLight}
          darkPath={DeleteCategoryDark}
        />
      }
      buttons={[
        {
          label: translate("common.cancel"),
          variant: Variant.Info,
          onClick: () => props.setPopupVisible(false),
        },
        {
          label: translate("common.delete"),
          onClick: () => props.onDeleteCategory(props.categoryKey),
        },
      ]}
    />
  );
}
