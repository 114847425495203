import styles from "./CategoryScreen.module.css";
import {
  CategoryKey,
  DateFormat,
  IItem,
  SortMethod,
} from "@warranty-keeper/common";
import Screen from "../../components/basic/Screen/Screen";
import ItemList from "../../components/item/ItemList/ItemList";
import Illustration from "../../components/basic/Illustration/Illustration";
import NoItemsLight from "../../assets/illustrations/no-items-in-category-light.svg";
import NoItemsDark from "../../assets/illustrations/no-items-in-category-dark.svg";
import { reportClick } from "../../firebase/FirebaseAnalytics";
import Fab from "../../components/basic/Fab/Fab";
import { translate } from "../../locale/Locale";
import IconButton from "../../components/basic/IconButton";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { IconColorField } from "../../components/basic/IconButton/IconButton";
import { useState } from "react";
import DeleteCategoryPopup from "../../components/category/CategoryPreview/DeleteCategoryPopup";

export interface CategoryScreenProps {
  items: IItem[];
  dateFormat: DateFormat;
  categoryKey: CategoryKey;
  categoryLabel: string;
  onAddItemFromCategory: () => void;
  sortingMethod: SortMethod;
  onClickOnItem: (item: IItem) => void;
  onDeleteCategory: (categoryKey: CategoryKey) => void;
}

export default function CategoryScreen(props: CategoryScreenProps) {
  const noItems = props.items.length === 0;
  const [popupVisible, setPopupVisible] = useState(false);

  const DeleteButton = (
    <IconButton
      onClick={() => setPopupVisible(true)}
      icon={TrashIcon}
      iconColorField={IconColorField.STROKE}
      className={styles.editButton}
      disabled={props.items.length > 0}
      analyticTag={"category/delete"}
      testId="deleteCategory"
    />
  );

  return (
    <Screen headerTitle={props.categoryLabel} rightButton={DeleteButton}>
      <div className={styles.content}>
        {noItems ? (
          <div
            className={styles.noItems}
            onClick={() => {
              props.onAddItemFromCategory();
              reportClick("emptyCategory/addItemFromBackground");
            }}
          >
            <div>
              <Illustration lightPath={NoItemsLight} darkPath={NoItemsDark} />
              <div>
                {translate("categories.noItems", {
                  categoryName: props.categoryLabel,
                })}
              </div>
              <div>{translate("allItems.addItemsTip")}</div>
            </div>
          </div>
        ) : (
          <ItemList
            sortingMethod={props.sortingMethod}
            items={props.items}
            dateFormat={props.dateFormat}
            onClickOnItem={props.onClickOnItem}
          />
        )}
      </div>

      <Fab onClick={props.onAddItemFromCategory} />

      <DeleteCategoryPopup
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
        categoryKey={props.categoryKey}
        categoryLabel={props.categoryLabel}
        onDeleteCategory={props.onDeleteCategory}
      />
    </Screen>
  );
}
