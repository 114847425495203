import styles from "./IconButton.module.scss";
import classNames from "classnames";
import { Icon } from "../../../utils/IconUtils";
import { reportClick } from "../../../firebase/FirebaseAnalytics";

export enum IconColorField {
  FILL = "fill",
  STROKE = "stroke",
}

export interface IconButtonProps {
  icon?: Icon;
  title?: string;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  iconColorField: IconColorField;
  className?: string;
  textClassName?: string;
  analyticTag?: string;
  testId?: string;
}

export default function IconButton(props: IconButtonProps) {
  let colorClass: string | undefined;

  switch (props.iconColorField) {
    case IconColorField.FILL:
      if (props.disabled) {
        colorClass = styles.disabledFill;
      } else if (props.selected) {
        colorClass = styles.selectedFill;
      } else {
        colorClass = styles.notSelectedFill;
      }
      break;
    case IconColorField.STROKE:
      if (props.disabled) {
        colorClass = styles.disabledStroke;
      } else if (props.selected) {
        colorClass = styles.selectedStroke;
      } else {
        colorClass = styles.notSelectedStroke;
      }
      break;

    default:
      colorClass = undefined;
      break;
  }

  const containerClasses = [styles.container, colorClass, props.className];
  if (!props.disabled) {
    containerClasses.push(styles.enabled);
  }

  const onClick = () => {
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
    if (props.analyticTag) {
      reportClick(props.analyticTag);
    }
  };

  const iconClasses = [colorClass, styles.icon];

  return (
    <div
      className={classNames(containerClasses)}
      onClick={onClick}
      data-test-id={props.testId}
      data-disabled={props.disabled}
    >
      {props.icon && <props.icon className={classNames(iconClasses)} />}
      {props.title && (
        <div className={classNames(styles.title, props.textClassName)}>
          {props.title}
        </div>
      )}
    </div>
  );
}
